import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <Svg viewBox="0 0 1024 1024" {...props}>
      <path d="M132.59781895 652.79088889C126.05955465 704.69539067 231.42681763 858.54005527 480.66267125 759.3522821 570.50088704 714.73036803 631.74423639 646.94875421 636.48862811 599.24245003 650.87173878 561.17486492 645.13134613 475.19213674 769.82424087 357.73899103 824.8934552 304.46900411 910.50134438 238.40629511 914.40502556 213.15820628 917.67683518 192.01728262 865.83123678 147.94156261 813.19847623 175.67429913 759.35552103 209.70433187 632.22081757 375.1475885 604.35956484 395.22289815 489.62667988 499.37461166 394.64245916 505.91823091 347.32705866 518.91979184 309.65037702 541.56007178 139.13608336 610.52510601 132.59781895 652.79088889Z" fill="rgb(99, 48, 1)" p-id="1639" />
      <path d="M148.8262145 653.78351404C142.74846667 698.39471851 249.17598883 833.43317145 480.82653507 739.46101616 564.32461571 697.1845237 616.52363394 635.51278676 625.40731985 594.88023146 634.29100588 554.24767605 632.67384306 465.18591259 748.56872914 353.91225678 796.06083954 304.70124151 898.50435781 220.04115975 898.50435781 220.04115975 910.20469168 195.75694268 862.62154903 161.63587749 818.18169959 187.91209647 763.65867914 220.15361146 641.46649667 383.08544621 620.05247601 402.10585163 508.93946553 500.77955984 402.51729824 513.73828199 363.01996973 530.6221048 323.52264122 547.50592753 154.90396233 609.17230966 148.8262145 653.78351404Z" fill="rgb(255, 255, 255)" p-id="1640" />
      <path d="M603.03597207 495.81561949C594.88054484 558.45122849 479.0766911 676.14534211 420.97128012 694.48032754 358.76405886 728.42468281 219.19005309 700.63304309 196.06783712 678.41579591 182.58434021 665.83726753 614.38824116 464.27023424 603.03597207 495.81561949Z" fill="rgb(209, 136, 79)" p-id="1641" />
      <path d="M380.27446663 329.51796785C466.03764624 329.51796785 535.56493919 399.04526087 535.56493919 484.80844046 535.56493919 570.57162011 466.03764624 640.09891305 380.27446663 640.09891305 294.51128709 640.09891305 224.98399414 570.57162011 224.98399414 484.80844046 224.98399414 399.04526087 294.51128709 329.51796785 380.27446663 329.51796785Z" fill="#633001" p-id="1642" />
      <path d="M381.49586684 346.34280142C459.28559893 346.34280142 522.34890319 409.40610574 522.34890319 487.19583779 522.34890319 564.98556989 459.28559893 628.04887415 381.49586684 628.04887415 303.70613483 628.04887415 240.64283058 564.98556989 240.64283058 487.19583779 240.64283058 409.40610574 303.70613483 346.34280142 381.49586684 346.34280142Z" fill="rgb(255, 255, 255)" p-id="1643" />
    </Svg>
  );
};

export default Icon;
 