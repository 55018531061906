import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <Svg viewBox="0 0 1024 1024" {...props}>
     <path d="M881.359477 495.825708a17.457081 17.457081 0 0 0 0 27.050109 218.743355 218.743355 0 0 1 77.803922 165.368192 229.814379 229.814379 0 0 1-235.363835 223.093682h-57.446623v103.459695a8.756427 8.756427 0 0 1-8.923747 8.64488h-55.494553a8.756427 8.756427 0 0 1-8.923748-8.64488v-103.459695h-147.799564v103.459695a8.756427 8.756427 0 0 1-8.923747 8.64488h-55.494554a8.756427 8.756427 0 0 1-8.923747-8.64488v-103.459695H150.169935a83.102397 83.102397 0 0 1-85.054467-80.592593 80.592593 80.592593 0 0 1 49.638345-73.342048V261.298475A80.592593 80.592593 0 0 1 65.115468 187.956427a83.102397 83.102397 0 0 1 85.054467-80.592593h221.978213V9.202614a9.174728 9.174728 0 0 1 8.923747-8.923747h55.773421a9.174728 9.174728 0 0 1 8.923747 8.923747v98.16122h147.520697V9.202614a9.174728 9.174728 0 0 1 8.923748-8.923747h55.77342a9.174728 9.174728 0 0 1 8.923747 8.923747v98.16122h56.888889a229.814379 229.814379 0 0 1 235.363835 223.093682 218.743355 218.743355 0 0 1-77.803922 165.368192z m-139.991285-114.056645a97.213072 97.213072 0 0 0-99.555556-94.535948h-243.45098a97.213072 97.213072 0 0 0-99.555556 94.535948v369.220044a22.309368 22.309368 0 0 0 37.647059 15.058823l63.302832-58.562091h242.056645a97.213072 97.213072 0 0 0 99.555556-94.535948v-231.180828z m-136.64488 151.982571a34.886275 34.886275 0 1 1 36.53159-34.858388 35.862309 35.862309 0 0 1-36.53159 34.858388z m-163.69499 0a34.914161 34.914161 0 1 1 36.810458-34.858388 35.862309 35.862309 0 0 1-36.810458 34.858388z"   p-id="5132"></path>
    </Svg>
  );
};

export default Icon;
